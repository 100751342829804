//require('./bootstrap');


$(document).ready(() => {

    document.querySelectorAll('.confirm-dialog-delete').forEach((element) => {
        element.addEventListener('click', (event) => {
            event.preventDefault();
            let href = element.href;
            document.querySelector('#confirm-delete-modal #delete-item').href = href;
            $('#confirm-delete-modal').modal('show');
        })
    })
})